<script context="module">
  import { isLoading, waitLocale, _ } from "svelte-i18n";
  import { get_global_data } from "../services/global-data";
  import GoogleAnalytics from "../components/GoogleAnalytics.svelte";

  export async function preload(page, session) {
    const GD = await get_global_data({ module: this });

    await waitLocale();

    return {
      inputs: {
        dfs_products: GD.dfs_products,
        main_cat_ids: GD.main_cat_ids,
        page_failed: GD.page_failed,
        page_endpoints: GD.page_endpoints,
      },
    };
  }
</script>

<script>
  import { stores } from "@sapper/app";
  import { media } from "svelte-match-media";

  import Nav from "../components/Nav.svelte";
  import HomeHeaderDesktop from "../components/HomeHeaderDesktop.svelte";
  import MobileHeaderToolbar from "../components/MobileHeaderToolbar.svelte";
  import { color_aussen } from "../style";
  import { onMount } from "svelte";

  import { hard_reload } from "../services/global-data";

  export let segment;
  export let inputs;

  let ga_measurment_id = true ? "G-ETC1M77840" : "G-HXP7968Y4V";
</script>

<svelte:head>
  <link
    rel="manifest"
    href="/manifest.json?v={Math.round(Date.now())}"
    crossorigin="use-credentials"
  />

  <link rel="icon" href="/dfs-logo-combined.svg" type="image/svg+xml" />
  <!-- generics -->
  <link
    rel="icon"
    href="/favicon/favicon-32.png?v={Math.round(Date.now())}"
    sizes="32x32"
  />
  <link
    rel="icon"
    href="/favicon/favicon-57.png?v={Math.round(Date.now())}"
    sizes="57x57"
  />
  <link
    rel="icon"
    href="/favicon/favicon-76.png?v={Math.round(Date.now())}"
    sizes="76x76"
  />
  <link
    rel="icon"
    href="/favicon/favicon-96.png?v={Math.round(Date.now())}"
    sizes="96x96"
  />
  <link
    rel="icon"
    href="/favicon/favicon-128.png?v={Math.round(Date.now())}"
    sizes="128x128"
  />
  <link
    rel="icon"
    href="/favicon/favicon-192.png?v={Math.round(Date.now())}"
    sizes="192x192"
  />
  <link
    rel="icon"
    href="/favicon/favicon-228.png?v={Math.round(Date.now())}"
    sizes="228x228"
  />
  <link
    rel="icon"
    href="/favicon/favicon-512.png?v={Math.round(Date.now())}"
    sizes="512x512"
  />

  <!-- Android -->
  <link
    rel="shortcut icon"
    sizes="196x196"
    href="/favicon/favicon-196.png?v={Math.round(Date.now())}"
  />

  <!-- iOS -->
  <link
    rel="apple-touch-icon"
    href="/favicon/favicon-120.png?v={Math.round(Date.now())}"
    sizes="120x120"
  />
  <link
    rel="apple-touch-icon"
    href="/favicon/favicon-152.png?v={Math.round(Date.now())}"
    sizes="152x152"
  />
  <link
    rel="apple-touch-icon"
    href="/favicon/favicon-180.png?v={Math.round(Date.now())}"
    sizes="180x180"
  />

  <!-- Windows 8 IE 10 -->
  <meta name="msapplication-TileColor" content="#FFFFFF" />
  <meta
    name="msapplication-TileImage"
    content="/favicon/favicon-144.png?v={Math.round(Date.now())}"
  />

  <!-- Windows 8.1 + IE11 and above -->
  <meta
    name="msapplication-config"
    content="ieconfig.xml?v={Math.round(Date.now())}"
  />

  <script defer src="/slideout.min.js"></script>
</svelte:head>

<GoogleAnalytics {stores} id={ga_measurment_id} />

{#if !$isLoading}
  <div id="main-content" style={`background-color: ${$color_aussen}`}>
    {#if $media && $media.mobile}
      <MobileHeaderToolbar {inputs}>
        <slot />
      </MobileHeaderToolbar>
    {:else}
      <div style="background-color: black;">
        <HomeHeaderDesktop />
        <Nav {segment} {inputs} />
      </div>

      <main>
        <slot />
      </main>
    {/if}
  </div>
{/if}

<style>
  main {
    position: relative;
    margin: 0 auto;
    box-sizing: border-box;
  }
</style>
