<script>
  export let mobile_display = false;

  import { onDestroy, onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import { get_version_string, hard_reload } from "../services/global-data";
  import { has_new_version } from "../stores";

  let container;
  let mobile_version_container;
  let in_prompt = false;

  let show_update_now = false;

  async function check_for_update() {
    if (typeof document === "undefined") {
      return;
    }

    let version_string = await get_version_string();
    let version_html = `${$_(
      "last_update"
    )}: <span style="white-space: nowrap;">${version_string}</span>`;

    if (container) {
      container.innerHTML = version_html;
    }

    if (mobile_version_container) {
      mobile_version_container.innerHTML = version_html;
    }

    let have_update = $has_new_version || window.fakeversion;
    show_update_now = have_update;
    if (have_update) {
      await reload_now();
    }
  }

  let sublangchange = _.subscribe(() => {
    setTimeout(() => check_for_update(), 0);
  });

  async function check_for_content() {
    let marker = document.getElementById("golden-spirits-content-marker");

    let cc = container;
    if (!cc) {
      cc = mobile_version_container;
    }

    let good_version =
      (container && container.innerHTML != "") ||
      (mobile_version_container && mobile_version_container.innerHTML != "");

    if (marker && good_version) {
    } else {
      let can_reload = false;
      try {
        let response = await fetch("/api/version");
        let version_res = await response.json();
        can_reload = version_res != null;
      } catch (e) {
        console.warn(e);
      }

      if (!can_reload) {
        console.warn(
          "No content marker but not reloading since version endpoint not available"
        );
        return;
      }

      await reload_now();
    }
  }

  let update_interval_handle;
  onMount(() => {
    setTimeout(() => check_for_update(), 250);
    setTimeout(() => check_for_update(), 5000);
    update_interval_handle = setInterval(check_for_update, 60 * 1000);
    update_interval_handle = setInterval(check_for_content, 1000);
  });

  onDestroy(() => {
    sublangchange();
    if (update_interval_handle) {
      clearInterval(update_interval_handle);
    }
  });

  async function reload_now() {
    hard_reload();
  }
</script>

{#if mobile_display}
  {#if show_update_now}
    <div
      style="text-decoration: underline;cursor: pointer;"
      on:click={reload_now}
    >
      {`${$_("new_version")}: ${$_("update_now")}`}
    </div>
  {:else}
    <div bind:this={mobile_version_container} />
  {/if}
{:else}
  <div
    class="pulsating-text"
    style="padding-top: 10px;color: #00ff00; font-weight: bold;"
  >
    <div bind:this={container} />
    {#if show_update_now}
      <div
        style="text-decoration: underline;cursor: pointer;"
        on:click={reload_now}
      >
        {`${$_("new_version")}: ${$_("update_now")}`}
      </div>
    {/if}
  </div>
{/if}
<div class="loaded-version version_from_html">
  {1743609519000}
</div>

<style>
  .loaded-version {
    display: none;
  }

  @keyframes pulsate {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1); /* Slightly larger scale */
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .pulsating-text {
    animation: pulsate 5s infinite; /* Continuous animation */
    font-size: 18px;
  }
</style>
