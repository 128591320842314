<script>
  import { goto, stores } from "@sapper/app";

  import { lang } from "../stores";
  import { _ } from "svelte-i18n";
  import { media } from "svelte-match-media";

  export let has_submit_button = false;
  export let is_desktop;

  let { page } = stores();  

  let search_element;

  async function mySubmit(e) {
    e.preventDefault();
    try {
      if (search_element) {
        let query = "" + search_element.value;
        query = query.trim();
        if (query.length > 0) {
          if (typeof gtag !== "undefined") {
            window.gtag("event", "product-search", {
              event_category: "product-search",
              event_label: query,
            });
          } else {
            console.warn("gtag is not defined");
          }

          await goto(`/${$lang}/products?q=${query}`);
          search_element.value = "";
          let is_mobile = !($media && $media.md);
          if (!is_mobile) {
            search_element.focus();
          }
        } else {
        }
      } else {
        // TODO?
      }
    } catch (e) {
      console.error(e.message);
    }

    return false;
  }

  //let form_style = `${
  //  inline ? "display: inline-flex;align-items: center;" : ""
  //}`;
  let form_style = is_desktop ? `margin-left: 5px; display: flex; margin: 5px;` : 'width: 100%;'
  let input_style = !is_desktop ? `--background-color: #FEEED5` : `margin-left: 5px;
cursor: pointer;
background-color: #feeed5;
font-size: 14px;
background-image: url(https://img.icons8.com/ios-glyphs/30/000000/search--v1.png);
background-repeat: no-repeat;
background-position: center center;
padding-left: 40px;
box-shadow: none;
border-radius: 5px;
border: none;
min-width: 50px;`

</script>

<form on:submit={mySubmit} style={form_style}>
  <div class="container">
    <div class="Icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#657789"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-search"
        ><circle cx="11" cy="11" r="8" style="stroke: black;" /><line
          style="stroke: black;"
          x1="21"
          y1="21"
          x2="16.65"
          y2="16.65"
        /></svg
      >
    </div>
    <div class="InputContainer">
      <input
        bind:this={search_element}
        style="--background: #FEEED5"
        id="desktop-searchbar"
        value={$page.query.q ? $page.query.q : ""}
        animated
        placeholder={$_("searchbox_placeholder")}
        enterkeyhint="search"
        inputmode="search"
        type="search"
      />
    </div>
  </div>

  {#if has_submit_button}
    <input type="submit" value="" style={input_style} />
  {/if}
</form>

<style>
  .container {
    display: flex;
    background-color: #feeed5;
  }

  .Icon {
    min-width: 46px;
    min-height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    margin-right: 12px;
  }
  .Icon svg {
    transform: translate(-1px, -1px);
  }

  .InputContainer {
    width: 100%;
    display: flex;
  }

  #desktop-searchbar {
    background-color: #feeed5;
    font-size: 18px;
    line-height: 2.5;
    border: none;
    display: block;
    font-family: "Orbitron", sans-serif;
    color: #a9b8c9;
    -webkit-appearance: none;
    transition: all 240ms ease-out;
    width: 100%;
    color: black;
  }
  #desktop-searchbar::placeholder {
    color: black;
  }
  #desktop-searchbar:focus {
    outline: none;
    background-color: #feeed5;
  }

  .InputContainer {
    position: relative;
    border-radius: var(--border-radius);
    overflow: hidden;
  }
  .InputContainer:before,
  .InputContainer:after {
    left: 0;
    top: 0;
    display: block;
    content: "";
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .InputContainer:before {
    box-shadow: var(--bottom-shadow);
  }
  .InputContainer:after {
    box-shadow: var(--top-shadow);
  }
</style>
