<script>
  import { _ } from "svelte-i18n";
  import { links } from "../stores";

  import Version from "./Version.svelte";
</script>

<div class="row" style="margin-bottom: 10px; max-width: 1920px;">
  <div class="col" style="justify-content: center;align-items: center;display: flex; flex: 0.75 0;">
    <a href="." rel=external>
      <img
        loading="lazy"
        src="g/images/dfs-logo-white-small.png"
        alt="DFS logo"
        width="399"
        height="320"
        style="width:100%; height:auto;max-height: 132px;object-fit: contain;"
      />
    </a>
  </div>
  <div class="col" style="flex: 1;display: flex;">
    <div class="stuff text_thing">
      <div class="" style="font-size: xxx-large;">
        <a rel=external href={$links.home} style="color: white;text-decoration: none;"
          >{$_("banner_slogan")}</a
        >
      </div>
      <ul style="display: table;padding-left: 0px;">
        <li style="padding: 0px;"><a href={`${$links.products}/48/201`}>{$_("catid.201")}</a></li>
        <li><a href={`${$links.products}/48/203`}>{$_("catid.203")}</a></li>
        <li><a href={`${$links.products}/54/136`}>{$_("catid.54")}</a></li>

        <li>
          <a href={`${$links.products}/107/207`}>{$_("catid.107")}</a>
        </li>
        <li><a href={`${$links.products}/42/160`}>{$_("catid.42")}</a></li>
        <li><a href={`${$links.products}/77/166`}>{$_("catid.77")}</a></li>
      </ul>
      <Version />
    </div>
  </div>

  <div class="col" style="overflow: hidden; display: flex; align-items: center; flex: 0.8; justify-content: center;">
    <video loop muted preload="none" autoplay style="margin: -30px 0;width: 100%; max-width: 393px;object-fit: contain;">
      <source src="/slotmachine-voodoomagic.webm?v=2" type="video/webm" />
    </video>
  </div>
</div>

<style>
  ul {
    display: table;
    color: white;
    margin: 0 auto;
    /*    text-decoration: underline;*/
    font-size: x-large;
  }
  li {
    display: table-cell;
    padding: 0px 0px 0px 25px;
    font-family: cursive;
  }

  li a {
    color: white;
  }

  .stuff {
    font-size: 39px;
    color: white;
    text-align: center;
  }

  .text_thing {
    /*text-shadow: 1px 1px 0 rgb(128, 128, 128), 2px 2px 1px rgba(255, 102, 00, 1);*/
    color: #ffffff;
    font-family: "Times New Roman", Times, serif;
  }

  .row {
    display: flex;
    /*flex-wrap: wrap;*/
}

</style>
